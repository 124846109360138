import clsx from 'clsx';
import { useMemo, useState } from 'preact/hooks';
import { useTimeout } from 'usehooks-ts';
import Spinner from '../../components/Spinner';
import { TDevice } from '../../hooks/useUserDevice';
import { QrCodeGenerator } from './QrCodeGenerator';
import { CopyToClipboardTrigger } from './CopyToClipboardTrigger';
import ClipboardImg from './assets/clipboard.svg';
import useWindowDimensions from '../../hooks/useWindowDimenstions';

import Logo8thWall from '../../assets/splash/8thwall.svg';
import EJLogo from '../../assets/splash/ej-logo.svg';
import Logo from '../../assets/splash/logo.webp';
import LogoDesktop from '../../assets/splash/logo-desktop.webp';

type SplashProps = {
    onPermissionsGranted: () => void;
    isLoadingExperience: boolean;
    device: TDevice | undefined;
};

export function Splash({
    onPermissionsGranted,
    isLoadingExperience,
    device,
}: SplashProps) {
    const [permissionDenied, setPermissionDenied] = useState<
        'camera-denied' | 'motion-denied' | undefined
    >(undefined);

    const requestCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then(() => {
                onPermissionsGranted();
            })
            .catch((err) => {
                setPermissionDenied('camera-denied');
                console.log('Error: camera rejected.' + err);
            });
    };

    const checkCameraPermissions = () => {
        if (navigator.permissions) {
            navigator.permissions
                .query({ name: "camera" })
                .then((permissionStatus) => {
                    if (permissionStatus.state === "granted") {
                        onPermissionsGranted();
                    } else if (
                        permissionStatus.state === "prompt" ||
                        permissionStatus.state === "denied"
                    ) {
                        if (device === "iOS") {
                            requestCamera();
                        } else {
                            onPermissionsGranted();
                        }
                    }
                })
                .catch((err) => {
                    console.log("Error checking camera permissions:", err);
                    if (device === "iOS") {
                        requestCamera();
                    } else {
                        onPermissionsGranted();
                    }
                });
        } else {
            if (device === "iOS") {
                requestCamera();
            } else {
                onPermissionsGranted();
            }
        }
    };

    const requestMotion = () => {
        // video and audio need to unmute on a user click
        const video = document.getElementById('ej-video') as HTMLVideoElement;
        const audio = document.getElementById('ejx-audio') as HTMLAudioElement;
        if (video) video.muted = false;
        if (audio) {
            audio.pause();
            audio.muted = false;
            // console.log('audio unmuted');
        }
        // imageTargetVideo.init.muted = false
        if (
            typeof DeviceMotionEvent !== 'undefined' &&
            typeof (DeviceMotionEvent as any).requestPermission === 'function'
        ) {
            // motion avaiable
            (DeviceMotionEvent as any)
                .requestPermission()
                .then((response: string) => {
                    if (response === 'granted') {
                        checkCameraPermissions();
                    } else {
                        console.log(response);
                        setPermissionDenied('motion-denied');
                    }
                })
                .catch((err: Error) => {
                    setPermissionDenied('motion-denied');
                    console.log('Error: motion rejected.' + err);
                });
        } else {
            checkCameraPermissions();
        }
    };

    const [pageType, setPageType] = useState<'loading' | 'default'>('loading');
    const uiType = useMemo(() => {
        if (pageType === 'loading') {
            return 'loading';
        } else return 'default';
    }, [pageType]);

    useTimeout(() => {
        setPageType('default');
    }, 2000);

    const deviceType = useMemo(() => {
        if (device === undefined) {
            return 'loading';
        } else if (device === 'iOS' || device === 'Android') {
            return 'mobile';
        } else return 'desktop';
    }, [device]);

    return (
        <div
            className={clsx(
                'flex-col xpb-12 xbg-[#181146] bg-cover bg-center h-[100dvh] w-full flex text-white pointer-events-auto items-center ',
                {
                    ' bg-[url("./assets/splash/desktop-bg.webp")]':
                        deviceType === 'desktop',
                    'flex-col bg-[url("./assets/splash/bg-mobile.webp")] px-5 pt-24 pb-16':
                        deviceType !== 'desktop',
                },
            )}
        >
            {deviceType === 'desktop' && (
                // <div className="flex flex-col items-center justify-center w-full h-full p-[15%] min-w-[780px]">
                <DesktopSplash />
                // </div>
            )}
            {deviceType === 'mobile' && (
                <>
                    <div className="flex items-emd justify-center flex-row gap-4 pb-2 ">
                        <img src={Logo} className="max-w-[388px] w-full" />
                    </div>
                </>
            )}
            {deviceType === 'loading' && (
                <div className="flex flex-col justify-center items-center px-10 pb-10 basis-1/2 h-full">
                    <Spinner />
                </div>
            )}
            {deviceType === 'mobile' && (
                <>
                    {permissionDenied ? (
                        <PermissionsDeniedScreen
                            permissionDenied={permissionDenied}
                        />
                    ) : (
                        <div
                            className={clsx(
                                'flex flex-col items-center flex-grow basis-1/2 justify-end',
                                // {
                                //     'justify-end': uiType === 'default',
                                //     'justify-end': uiType === 'loading',
                                // },
                            )}
                        >
                            {uiType === 'loading' && (
                                <div className=" pb-20">
                                    <Spinner />
                                </div>
                            )}

                            {uiType === 'default' && (
                                <>
                                    <h2 className="text-base leading-[22px] pointer-events-none items-end text-center animate-fade-in pb-8 ">
                                        Access to your camera, microphone,
                                        <br />
                                        motion & orientation is required
                                    </h2>
                                </>
                            )}

                            {uiType !== 'loading' && (
                                <>
                                    <button
                                        className={clsx(
                                            'button-default',
                                            'animate-fade-in',
                                            'disabled:opacity-50',
                                            'hover:bg-white active:bg-white hover:text-black active:text-black ',
                                        )}
                                        onClick={requestMotion}
                                        disabled={isLoadingExperience}
                                    >
                                        {isLoadingExperience ? (
                                            <span className="animate-fade-in pointer-events-none">
                                                <Spinner
                                                    variant="primary"
                                                    size="md"
                                                />
                                            </span>
                                        ) : (
                                            <span className="relative">
                                                START AR
                                            </span>
                                        )}
                                    </button>
                                </>
                            )}

                            {pageType === 'loading' && (
                                <>
                                    <div className="flex w-full justify-center items-center flex-wrap gap-6 absolute bottom-0 mb-[5dvh] pointer-events-none">
                                        <img src={Logo8thWall} />
                                        <img src={EJLogo} />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

function DesktopSplash() {
    const dimensions = useWindowDimensions();

    return (
        <div className=" px-5 pt-10 pb-12 short:justify-center justify-start overflow-auto flex flex-col 3xl:gap-14 xmd:gap-6 gap-12 h-full w-full items-center xjustify-center xmd:flex-col x[@media_((max-height:690px)_and_(min-width:768px))]:flex-row ">
            <div className="flex-shrink flex flex-col items-center justify-start gap-4 w-full">
                <img
                    src={LogoDesktop}
                    className="3xl:max-w-[506px] max-w-[350px] w-full"
                />
            </div>
            <div className="w-full flex flex-col 3xl:gap-14 md:gap-7 gap-5 items-center justify-center">
                <QrCodeGenerator
                    value={window.location.href}
                    fgColor="#181146"
                    size={dimensions.height > 1200 ? 250 : 150}
                />
                <h2 className="text-white text-center 3xl:text-[22px] text-lg font-helvetica-light 3xl:leading-[26px] leading-[22px] tracking-[-0.41px]">
                    Scan the QR code to launch
                    <br />
                    The Next Dimension Experience.
                </h2>
                <CopyToClipboardTrigger text={window.location.href}>
                    <div className="border-2 min-w-[250px] border-white border-opacity-30 py-3 px-4 max-w-xs flex gap-x-2 relative cursor-pointer hover:opacity-50 hover:border-opacity-50">
                        <span className="link flex-grow text-center font-helvetica-bold max-w-[310px] whitespace-nowrap overflow-hidden text-ellipsis w-full">
                            COPY LINK
                        </span>
                        <img
                            className="cursor-pointer absolute right-3"
                            src={ClipboardImg}
                        />
                    </div>
                </CopyToClipboardTrigger>
            </div>
        </div>
    );
}

type PermissionsDeniedScreenProps = {
    permissionDenied: 'camera-denied' | 'motion-denied' | undefined;
};
function PermissionsDeniedScreen({
    permissionDenied,
}: PermissionsDeniedScreenProps) {
    return (
        <div className="flex flex-col flex-grow justify-start gap-y-10 justify-center pt-6 items-center px-10 mb-12 basis-1/2">
            {permissionDenied === 'camera-denied' && (
                <>
                    <h2 className="font-helvetica-light text-xl text-center">
                        Camera access is required.
                    </h2>
                    <h2 className="font-helvetica-light text-xl text-center">
                        Quit and open the browser again.
                    </h2>
                </>
            )}
            {permissionDenied === 'motion-denied' && (
                <>
                    <h2 className="font-helvetica-light text-xl text-center ">
                        Motion and orientation access is required.
                    </h2>
                    <h2 className="font-helvetica-light text-xl text-center ">
                        Quit and open the browser again.
                    </h2>
                </>
            )}
        </div>
    );
}
